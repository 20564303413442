import React from 'react'
import { IFormSubmitInput } from "./FormSubmitInput.types"
import * as scss from './FormSubmitInput.module.scss'

export const FormSubmitInput: React.FC<IFormSubmitInput> = (props) => {

  const {
    id,
    primary: {
      input_label,
      input_url

    },
  } = props.data

  const { register } = props

  return (
    <div className={scss.root}>
      <span className={`${scss.button} b-default b-default__gradient b-default__gradient--01`}>
        <span className={scss.icon}/>
        <input type="submit" value={input_label}/>
        <input
          className={scss.submit_url}
          type="text"
          name={input_label}
          value={input_url}
          {...register(input_label)}
        />
      </span>
    </div>
  )
}


