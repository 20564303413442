import React from 'react'

import { IContent } from "./Content.types"
import * as scss from './Content.module.scss'
import { RichText } from 'prismic-reactjs'

export const Content: React.FC<IContent> = ({
  data: {
    primary: {
      content: {
        richText
      },

    }
  }
}) => {
  return (
    <div className={`${scss.root} g-padding g-width g-margin`}>
      <RichText render={richText} />
    </div>
  )
}


