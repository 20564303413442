import React, { Fragment } from "react";

import { ISliceZone } from "./SliceZone.types";
import { Link } from "./slices/link/Link";
import { Title } from "./slices/title/Title";
import { Content } from "./slices/Content/Content";
import { FormInputField } from "./slices/FormInputField/FormInputField";
import { FormSubmitInput } from "./slices/FormSubmitInput/FormSubmitInput";
import { NFTPreview } from "./slices/NFTPreview/NFTPreview";
import { Confirmation } from "./slices/Confirmation/Confirmation";
import { Informations } from "./slices/Informations/Informations";
import { ReferralCode } from "./slices/ReferralCode/ReferralCode";
import { Socials } from "./slices/Socials/Socials";

const SliceZone = ({ data, register, errors, control }: ISliceZone) => {
  const components = {
    link: Link,
    title: Title,
    content: Content,
    form_input_field: FormInputField,
    form_submit: FormSubmitInput,
    nft__preview_: NFTPreview,
    confirmation: Confirmation,
    informations: Informations,
    referral_code: ReferralCode,
    socials: Socials,
  };

  const sliceZoneContent = data.map((slice, index) => {
    const SliceComponent = components[slice.slice_type];
    if (SliceComponent) {
      return (
        <SliceComponent
          data={slice}
          key={`slice-${slice.id}`}
          id={slice.id}
          register={register}
          errors={errors}
          control={control}
        />
      );
    }
    return null;
  });

  return <Fragment>{sliceZoneContent}</Fragment>;
};

export default SliceZone;
