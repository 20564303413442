export const site = {
  title: "Fungible Apparel",
  titleDefault: "Fungible Apparel",
  titleAlt: "Fungible Apparel",
  url: "https://www.fungible-apparel.com/",
  dataDomain: "fungible-apparel.com",
  description: "Fungible Apparel",
  image: "src/assets/images/favicon.png",
  images: [
    {
      src: `src/assets/images/android-chrome-192x192.png`,
      sizes: `192x192`,
      type: `image/png`,
    },
    {
      src: `src/assets/images/android-chrome-512x512.png`,
      sizes: `512x512`,
      type: `image/png`,
    },
  ],

  background_color: "#FFFFFF",
  theme_color: "#155AC3",
  twitter: "@",
};
