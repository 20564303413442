import React, { useState, useRef } from 'react'

import { IModal } from "./Modal.types"
import * as scss from './Modal.module.scss'
import { RichText } from 'prismic-reactjs'
import SliceZone from '../sliceZone/SliceZone'

export const Modal: React.FC<IModal> = ({ body }) => {
  return (
    <div className={scss.root}>
      <div className={scss.wrapper}>
        <SliceZone data={body}/>
      </div>
    </div>
  )
}