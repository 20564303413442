import React,{ useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import gsap from 'gsap'

import linkResolver from 'utils/linkResolver'
import { RichText } from 'prismic-reactjs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import { IFormInputField } from "./FormInputField.types"
import * as scss from './FormInputField.module.scss'

export const FormInputField: React.FC<IFormInputField> = (props) => {

  const {
    id,
    primary: {
      field_label,
      field_name,
      field_placeholder,
      field_type,
      field_error,
      required,
      width,
      modal
    },
  } = props.data

  const { register, errors } = props
  const [isModalOpen, setModalOpen] = useState(false);
  const refTrigger = useRef<HTMLSpanElement>(null)
  const refModal = useRef<HTMLDivElement>(null)
  useOnClickOutside(refModal, () => setModalOpen(false));

  const requiredField = required ? {
    required: field_name,
  } : {}

  const requiredFieldEmail = required ? {
    required: field_name,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    }
  } : {}


  const handleClick = () => {
    setModalOpen(current => !current)
  }

  useEffect(() => {
    let tl = gsap.timeline()
    tl.to(refModal.current, { autoAlpha: isModalOpen ? 1 : 0 })
  }, [isModalOpen])

  return (
    <div className={`${scss.root} ${width ? scss.root__full : scss.root__half} ${errors[field_name] && scss.root__error}`}>
      <label htmlFor={field_type} className={scss.label}>{field_label}</label>
      { modal && modal.richText.length > 0 && (<span className={scss.modalTrigger} ref={refTrigger} onClick={handleClick}/>)}
      { modal && modal.richText.length > 0 && (<div className={scss.modal} ref={refModal}><RichText render={modal.richText}/></div>)}
      <input
        autocomplete="off"
        // className={errors[field_name] ? 'error' : ''}
        className={`${scss.input}`}
        type={field_type}
        name={field_name}
        placeholder={errors[field_name] ? field_error : field_placeholder}
        {...register(field_name, field_name === 'email' && required ? requiredFieldEmail : required ? requiredField : false )}
      />
    </div>
  )
}


