import React from "react";

import { INFTPreview } from "./NFTPreview.types";
import * as scss from "./NFTPreview.module.scss";
import { RichText } from "prismic-reactjs";
import placeholder from "assets/images/texture-placeholder.jpg";
import { useStoreGlobal } from "state/Global";

export const NFTPreview: React.FC<INFTPreview> = ({ data }) => {
  const { db_data } = useStoreGlobal();

  //const floorPrice = 23;
  //const totalVolume = "661.7k";

  return (
    <div className={scss.root}>
      <div className={scss.nft}>
        <div className={scss.image}>
          <img src={db_data.nft_img_url} />
          {db_data.nft_contract !== process.env.GATSBY_OS_STOREFRONT && (
            <div className={scss.tokenId}>#{db_data.nft_tokenid}</div>
          )}
        </div>
        {db_data.nft_collection !== "" && (
          <div className={scss.content}>
            <div className={scss.content__title}>Collection</div>
            <div className={scss.content__wrapper}>
              {/*<div className={scss.content__thumbnail}></div>*/}
              <div className={scss.informations}>
                <div className={scss.informations__title}>
                  {db_data.nft_collection}
                </div>
                {/*
              <div className={scss.informations__details}>
                <div className={scss.floor_price}>
                  <span className={scss.informations__details__title}>
                    Floor price
                  </span>
                  <span className={scss.logo_eth} />
                  <span className={scss.informations__details__value}>
                    {floorPrice}
                  </span>
                </div>
                <div className={scss.total_volum}>
                  <span className={scss.informations__details__title}>
                    Floor price
                  </span>
                  <span className={scss.logo_eth} />
                  <span className={scss.informations__details__value}>
                    {totalVolume}
                  </span>
                </div>
              </div>*/}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
