// extracted by mini-css-extract-plugin
export var content = "NFTPreview-module--content--neMia";
export var content__thumbnail = "NFTPreview-module--content__thumbnail--gfgyS";
export var content__title = "NFTPreview-module--content__title--rP7g7";
export var content__wrapper = "NFTPreview-module--content__wrapper--WIjmM";
export var floor_price = "NFTPreview-module--floor_price--4RN4h";
export var image = "NFTPreview-module--image--rcsRs";
export var informations__details = "NFTPreview-module--informations__details--snhk0";
export var informations__details__title = "NFTPreview-module--informations__details__title--UNkB9";
export var informations__details__value = "NFTPreview-module--informations__details__value--oPDSw";
export var informations__title = "NFTPreview-module--informations__title--N+8zU";
export var logo_eth = "NFTPreview-module--logo_eth--2V6PU";
export var nft = "NFTPreview-module--nft--xUgp4";
export var root = "NFTPreview-module--root--fRG1B";
export var tokenId = "NFTPreview-module--tokenId--TdD+r";
export var total_volum = "NFTPreview-module--total_volum--CQ168";