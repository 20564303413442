import React, { useRef } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ISocials } from "./Socials.types";
import * as scss from "./Socials.module.scss";
import { RichText } from "prismic-reactjs";

export const Socials: React.FC<ISocials> = ({ data }) => {
  return (
    <div className={`${scss.root}`}>
      <div className={scss.title}>
        <RichText render={data.primary.title.richText} />
      </div>
      <div className={scss.wrapper}>
        {data.items.map(({ icon, link }, index) => (
          <SocialNetwork
            key={`footer--SocialNetwork${index}`}
            icon={icon}
            link={link}
          />
        ))}
      </div>
    </div>
  );
};

const SocialNetwork = ({ icon, link }) => {
  return (
    <div className={scss.card}>
      <a href={link.url} target={link.target}>
        <GatsbyImage
          className={scss.poster__image}
          image={getImage(icon.localFile)}
          alt={icon.alt ? icon.alt : "Fungible Apparel"}
        />
      </a>
    </div>
  );
};
