import create from "zustand";
import { ICustomize } from './Global.types'
import { v4 as uuidv4 } from 'uuid'

const storeGlobal = (set: Function) => {
  return {
    confirmation: null,
    login: null,
    reset_login: false,
    nft: true,
    apparel: false,
    db_data:{},
    uid: null,
    SET_UID: (uid: string) => set((state: ICustomize) => ({ ...state, uid })),
    SET_NFT: (nft: boolean) => set((state: ICustomize) => ({ ...state, nft })),
    SET_APPAREL: (apparel: boolean) => set((state: ICustomize) => ({ ...state, apparel })),
    SET_DATA:(db_data: object) => set((state: ICustomize) => ({ ...state, db_data })),
    SET_CONFIRMATION: (confirmation: boolean) => set((state: ICustomize) => ({ ...state, confirmation })),
    SET_LOGIN: (login: boolean) => set((state: ICustomize) => ({ ...state, login })),
    SET_RESET_LOGIN: (reset_login: boolean) => set((state: ICustomize) => ({ ...state, reset_login })),
  }
}

const useStoreGlobal = create<any>((set: Function) => ({ ...storeGlobal(set) }))

export {
  useStoreGlobal,
}
