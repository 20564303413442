import React, { useRef } from "react";

import { IReferralCode } from "./ReferralCode.types";
import * as scss from "./ReferralCode.module.scss";
import { RichText } from "prismic-reactjs";
import { useStoreGlobal } from "state/Global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ReferralCode: React.FC<IReferralCode> = ({ data }) => {
  const form = useRef();
  const { db_data } = useStoreGlobal();
  const BASE_REFERRAL = "https://fungibleapparel.com/?ref=";

  const handleClick = () => {
    navigator.clipboard.writeText(`${BASE_REFERRAL}${db_data.referral_code}`);
    toast.success("Copied!", {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 400,
    });
  };

  return (
    <div className={`${scss.root}`}>
      {/*<a
        href="https://linktr.ee/fungibleapparel"
        target="_blank"
        rel="noopener noreferrer"
        className={scss.linktree}
      >
        Learn More
      </a>*/}
      {db_data && db_data.referral_code && (
        <>
          <div className={scss.title}>
            <RichText render={data.primary.title.richText} />
          </div>
          <form ref={form}>
            <input
              className={scss.input}
              type="text"
              readOnly
              //placeholder={data.primary.input_placeholder}
              value={`${BASE_REFERRAL}${db_data.referral_code}`}
            ></input>
            <div className={scss.submit} onClick={handleClick}>
              <div className={scss.icon} />
            </div>
          </form>
          <div className={scss.link}>
            <RichText render={data.primary.informations.richText} />
          </div>
        </>
      )}
    </div>
  );
};
