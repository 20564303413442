import React from 'react'

import { ITitle } from "./Title.types"
import * as scss from './Title.module.scss'
import { RichText } from 'prismic-reactjs'

export const Title: React.FC<ITitle> = ({
  data: {
    primary: {
      title: {
        richText
      },

    }
  }
}) => {
  return (
    <div className={`${scss.root} g-margin g-width`}>
      <RichText render={richText} />
    </div>
  )
}


