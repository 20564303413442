import React from "react";

import { IConfirmation } from "./Confirmation.types";
import * as scss from "./Confirmation.module.scss";
import { RichText } from "prismic-reactjs";
import { useStoreGlobal } from "state/Global";
import axios from "axios";

export const Confirmation: React.FC<IConfirmation> = ({ data }) => {
  const { SET_CONFIRMATION, db_data, uid } = useStoreGlobal();

  const handleClick = (value) => {
    //SET_CONFIRMATION(value === 'true' ? true : false)
    const form_url = `${process.env.GATSBY_BASE_URL}insertNFC`;
    const formatedData = {
      //email: db_data.user_email,
      product_id: db_data.product_id,
      uid: uid,
    };
    if (value === "true") {
      axios
        .post(form_url, formatedData)
        .then(() => {
          //TODO NFC already linked
          SET_CONFIRMATION(true);
        })
        .catch((r) => {
          console.log(r);
          alert(
            "Error while trying to link this NFT. Please try again later or contact our support"
          );
          SET_CONFIRMATION(false);
        });
    } else {
      SET_CONFIRMATION(false);
    }
  };

  return (
    <div className={`${scss.root}`}>
      <div className={scss.label}>
        Here is the NFT link{" "}
        {db_data.nft_contract !== process.env.GATSBY_OS_STOREFRONT &&
          `to #${db_data.nft_tokenid}`}
        <br />
        Do you want to continue with this NFT?
      </div>
      <div className={scss.buttons}>
        {data.items.map(({ input_label, input_value }) => (
          <div
            className={`${scss.button} b-default`}
            onClick={() => handleClick(input_value)}
          >
            <div className={scss.button__wrapper}>
              <RichText render={input_label.richText} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
