import React, { useState, useRef, useEffect } from "react";

import { ILogin } from "./Login.types";
import * as scss from "./Login.module.scss";
import { RichText } from "prismic-reactjs";
import { useForm } from "react-hook-form";
import axios from "axios";
import SliceZone from "../sliceZone/SliceZone";
import { useStoreGlobal } from "state/Global";

export const Login: React.FC<ILogin> = ({ content, body, form_url }) => {
  const [sucessForm, setSuccesForm] = useState(false);
  const [error, setError] = useState(false);
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const { SET_RESET_LOGIN, SET_LOGIN, SET_DATA, reset_login } =
    useStoreGlobal();

  const refForm = useRef<HTMLFormElement>(null);
  const refSuccess = useRef(null);
  const refSuccessContent = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      disciplines: [],
    },
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      refForm.current.reset();
    }
  };
  const onClose = () => setSuccesForm(false);

  const onSubmit = (data) => {
    setServerState({ submitting: true, status: null });
    const form_url = `${process.env.GATSBY_BASE_URL}verifyNFC`;
    const formatedData = {
      email: data.email,
      product_id: data.productId,
    };
    axios
      .post(form_url, formatedData)
      .then((r) => {
        handleServerResponse(true, "Thanks!", data);
        setSuccesForm(true);
        SET_DATA(r.data[0]);
        SET_LOGIN(true);
      })
      .catch((r) => {
        console.log(r);
        handleServerResponse(false, r.response.data.error, data);
        setError(true);
      });
  };

  useEffect(() => {
    if (reset_login) {
      refForm.current.reset();
      SET_RESET_LOGIN(false);
      SET_LOGIN(false);
    }
  }, [reset_login]);

  return (
    <div className={scss.root}>
      <div className={scss.title}>
        <RichText render={content.richText} />
      </div>
      {error && (
        <div className={scss.error}>
          Link failed. Values don't match, check again.
        </div>
      )}
      <div className={scss.body}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={refForm}
          className={scss.form}
        >
          <SliceZone
            data={body}
            register={register}
            errors={errors}
            control={control}
          />
        </form>
      </div>
    </div>
  );
};
