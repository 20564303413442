// extracted by mini-css-extract-plugin
export var attribute = "NFTDetails-module--attribute--IZGaJ";
export var attribute__name = "NFTDetails-module--attribute__name--98Wyf";
export var attribute__rarity = "NFTDetails-module--attribute__rarity--DDFha";
export var attribute__value = "NFTDetails-module--attribute__value--Cm4oj";
export var attributes = "NFTDetails-module--attributes--wQ7qo";
export var content = "NFTDetails-module--content--kq32z";
export var content__thumbnail = "NFTDetails-module--content__thumbnail--d+tdr";
export var content__title = "NFTDetails-module--content__title--Cd2Db";
export var content__wrapper = "NFTDetails-module--content__wrapper--yc+pr";
export var details = "NFTDetails-module--details--BNVYf";
export var details__thumbnail = "NFTDetails-module--details__thumbnail--0-JUn";
export var details__title = "NFTDetails-module--details__title--lu1+b";
export var details__wrapper = "NFTDetails-module--details__wrapper--HVZ+r";
export var floor_price = "NFTDetails-module--floor_price--HI8-C";
export var icon = "NFTDetails-module--icon--K-7Fj";
export var image = "NFTDetails-module--image--iDJ7i";
export var informations = "NFTDetails-module--informations--mpRqX";
export var informations__details = "NFTDetails-module--informations__details--6SFGm";
export var informations__details__apparel = "NFTDetails-module--informations__details__apparel--bPvKv";
export var informations__details__title = "NFTDetails-module--informations__details__title--9h2+V";
export var informations__details__value = "NFTDetails-module--informations__details__value--O3jHb";
export var informations__title = "NFTDetails-module--informations__title--3Hn16";
export var item = "NFTDetails-module--item--FPW2c";
export var link = "NFTDetails-module--link--K5GhO";
export var links = "NFTDetails-module--links--87az0";
export var logo_eth = "NFTDetails-module--logo_eth--wJRIZ";
export var nft = "NFTDetails-module--nft--UTXma";
export var owner = "NFTDetails-module--owner--lhrfn";
export var owner__thumbnail = "NFTDetails-module--owner__thumbnail---b1df";
export var owner__title = "NFTDetails-module--owner__title--xz0SG";
export var owner__wrapper = "NFTDetails-module--owner__wrapper--BYOlC";
export var root = "NFTDetails-module--root--5S1py";
export var tokenId = "NFTDetails-module--tokenId--1EHaE";
export var total_volum = "NFTDetails-module--total_volum--AOfor";