import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SCROLL from "utils/scroll";
import { useStoreGlobal } from "state/Global";
import { SEO } from "components/SEO/Seo";
import { Login } from "components/common/Login/Login";
import { Modal } from "components/common/Modal/Modal";
import { NFTDetails } from "components/common/sliceZone/slices/NFTDetails/NFTDetails";
import { NFTPreview } from "components/common/sliceZone/slices/NFTPreview/NFTPreview";
import * as scss from "./index.module.scss";
import { RichText } from "prismic-reactjs";
import SliceZone from "components/common/sliceZone/SliceZone";
import axios from "axios";

const PageTemplate: React.FC<any> = ({ data, location }) => {
  const {
    SET_RESET_LOGIN,
    SET_CONFIRMATION,
    SET_NFT,
    SET_APPAREL,
    SET_UID,
    SET_DATA,
    uid,
    confirmation,
    login,
    nft,
    apparel,
  } = useStoreGlobal();

  const refModal = useRef<HTMLDivElement>(null);
  const refConfirmation = useRef<HTMLDivElement>(null);
  const [isLinked, setIsLinked] = useState(false);
  const [missingUID, setMissingUID] = useState(false);

  const handleConfirmationSuccess = () => {};

  const handleClickTab = (index) => {
    if (index === 0) {
      SET_APPAREL(false);
      SET_NFT(true);
    } else {
      SET_APPAREL(true);
      SET_NFT(false);
    }
  };

  useEffect(() => {
    if (!login) return;
    const tl = gsap.timeline();
    tl.to(refModal.current, { autoAlpha: 1, pointerEvents: "auto" });
  }, [login]);

  useEffect(() => {
    if (confirmation) {
      const tl = gsap.timeline({
        onComplete: () => handleConfirmationSuccess(),
      });
      tl.to(refConfirmation.current, { autoAlpha: 1, pointerEvents: "auto" });
      tl.to(refModal.current, { autoAlpha: 0, pointerEvents: "none" });
    } else {
      const tl = gsap.timeline({ onComplete: () => SET_RESET_LOGIN(true) });
      tl.to(refModal.current, { autoAlpha: 0, pointerEvents: "none" });
    }
  }, [confirmation]);

  useEffect(() => {
    const uid = new URLSearchParams(location.search).get("id");
    if (uid) {
      const request = `${process.env.GATSBY_BASE_URL}/checkNFC/${uid}`;
      SET_UID(uid);

      axios
        .get(request, {})
        .then((res) => {
          if (res.data.length > 0) {
            SET_DATA(res.data[0]);
            setIsLinked(true);
          } else {
            setIsLinked(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMissingUID(true);
    }
  }, []);

  const returnHome = () => {
    window.location.reload();
  };

  if (missingUID) {
    return (
      <div className={scss.root}>
        <div className={`${scss.wrapper} g-margin g-width`}>
          <div className={scss.logo}></div>
          <div className={scss.missing}>Invalid NFC Chip</div>
        </div>
      </div>
    );
  }

  return (
    <div className={scss.root}>
      <div className={`${scss.wrapper} g-margin g-width`}>
        <a
          href="https://fungibleapparel.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={scss.logo}></div>
        </a>
        {!isLinked && (
          <div className={scss.login}>
            <Login
              content={data.page.data.content}
              body={data.page.data.body_login}
              form_url={data.page.data.form_url}
            />
          </div>
        )}
        {isLinked && (
          <div className={scss.contentWrapper}>
            <div className={scss.content__NFTDetails}>
              <NFTDetails data="null" />
            </div>
            <div className={scss.body_content}>
              <SliceZone data={data.page.data.body_content} />
            </div>
          </div>
        )}
      </div>

      <div className={`${scss.modal} g-margin g-width`} ref={refModal}>
        <Modal body={data.page.data.body_modal} />
      </div>

      <div
        className={`${scss.confirmation} g-margin g-width`}
        ref={refConfirmation}
      >
        <div className={scss.confirmation__wrapper} onClick={returnHome}>
          <div className={scss.confirmation__wrapper__icon}>
            <GatsbyImage
              className={scss.gatsbyImage}
              image={getImage(data.page.data.icon.localFile)}
              alt={
                data.page.data.icon.alt
                  ? data.page.data.icon.alt
                  : "Fungible Apparel"
              }
            />
          </div>
          <div className={scss.confirmation__wrapper__title}>
            <RichText render={data.page.data.content_validation.richText} />
          </div>
        </div>
      </div>
      {isLinked && (
        <div className={`${scss.content_navigation} g-margin g-width`}>
          <div className={scss.buttons}>
            {data.page.data.tabs.map(({ tabs_name }, index) => (
              <div
                className={`${scss.button} b-default ${
                  nft && index === 0 && scss.button__active
                } ${apparel && index === 1 && scss.button__active}`}
                onClick={() => handleClickTab(index)}
              >
                <div className={scss.button__wrapper}>
                  <RichText render={tabs_name.richText} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageTemplate;

export const Head = ({
  data: {
    page: {
      data: {
        meta_title = "Fungible Apparel - NFC Scan",
        meta_description = "NFC Scan Page for the Fungible Apparel Collection",
      },
    },
  },
}) => <SEO title={meta_title} description={meta_description} />;

export const query = graphql`
  {
    page: prismicNfcScan {
      data {
        form_url
        content {
          richText
        }
        content_validation {
          richText
        }
        icon {
          alt
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        tabs {
          tabs_name {
            richText
          }
        }
        body_login {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ... on PrismicNfcScanDataBodyLoginContent {
            primary {
              content {
                richText
              }
            }
          }
          ... on PrismicNfcScanDataBodyLoginFormInputField {
            primary {
              field_error
              field_label
              field_name
              field_placeholder
              field_type
              required
              modal {
                richText
              }
            }
          }
          ... on PrismicNfcScanDataBodyLoginFormSubmit {
            primary {
              input_label
              input_url
            }
          }
        }
        body_modal {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ... on PrismicNfcScanDataBodyModalConfirmation {
            primary {
              label {
                richText
              }
            }
            items {
              input_value
              input_label {
                richText
              }
            }
          }
          ... on PrismicNfcScanDataBodyModalInformations {
            primary {
              content_slice {
                richText
              }
            }
          }
          ... on PrismicNfcScanDataBodyModalNftPreview {
            id
          }
        }
        body_content {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ... on PrismicNfcScanDataBodyContentReferralCode {
            primary {
              input_placeholder
              input_value
              informations {
                richText
              }
              title {
                richText
              }
            }
          }
          ... on PrismicNfcScanDataBodyContentSocials {
            primary {
              title {
                richText
              }
            }
            items {
              icon {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              link {
                uid
                url
                type
                target
              }
            }
          }
        }
      }
    }
  }
`;
