import React, { Fragment, useState } from "react";

import { INFTDetails } from "./NFTDetails.types";
import * as scss from "./NFTDetails.module.scss";
import { RichText } from "prismic-reactjs";
import { useStoreGlobal } from "state/Global";

import openSeaImg from "assets/images/opensea.png";
import magicEdenImg from "assets/images/magiceden.png";
import etherScanImg from "assets/images/etherscan.png";
import polygonScanImg from "assets/images/polygon.png";
import optimismScanImg from "assets/images/optimism.png";
import arbiScanImg from "assets/images/arbiscan.png";
import solScanImg from "assets/images/solscan.png";

export const NFTDetails: React.FC<INFTDetails> = ({ data }) => {
  const { nft, apparel, db_data } = useStoreGlobal();

  const collectionName_apparel = "Fungible Apparel - Digital Twin";
  const visualizer_url = "https://visualizer.fungibleapparel.com/";
  const opensea_url = "https://opensea.io/";
  const magiceden_url = "https://magiceden.io/u/";

  const explorers = {
    ETH: { url: "https://etherscan.io/address/", logo: etherScanImg },
    MATIC: { url: "https://polygonscan.com/address/", logo: polygonScanImg },
    OP: {
      url: "https://optimistic.etherscan.io/address/",
      logo: optimismScanImg,
    },
    ARB: { url: "https://arbiscan.io/address/", logo: arbiScanImg },
    SOL: { url: "https://solscan.io/account/", logo: solScanImg },
  };

  const marketplace_url = db_data.mint_chain
    ? db_data.mint_chain !== "SOL"
      ? `${opensea_url}${db_data.wallet_address}`
      : `${magiceden_url}${db_data.wallet_address}`
    : `${opensea_url}${db_data.wallet_address}`;

  const explorer_url = db_data.nft_origin
    ? `${explorers[db_data.nft_origin].url}${db_data.wallet_address}`
    : `${explorers["ETH"].url}${db_data.wallet_address}`;

  const getAttributes = (_data) => {
    let res = [];
    let i = 1;
    if (_data) {
      let hasNext =
        _data.hasOwnProperty(`nft_trait${i}_value`) &&
        _data[`nft_trait${i}_value`] !== null;
      while (hasNext) {
        res.push({
          name: _data[`nft_trait${i}_type`],
          value: _data[`nft_trait${i}_value`],
        });
        i++;
        hasNext =
          _data.hasOwnProperty(`nft_trait${i}_value`) &&
          _data[`nft_trait${i}_value`] !== null;
      }
    }
    return res;
  };

  const getAttributesApparel = (_data) => {
    return [
      {
        name: "Apparel",
        value: _data.product_type,
      },
      {
        name: "Collection",
        value: _data.nft_collection,
      },
      {
        name: "Color",
        value: _data.product_color,
      },
      {
        name: "Placing",
        value: _data.product_design,
      },
      {
        name: "Size",
        value: _data.product_size,
      },
    ];
  };

  const formatWalletAddress = (address) => {
    if (!address || address === "") {
      return "N/A";
    }
    // Extract first 5 and last 4 characters
    const formattedAddress = address.slice(0, 5) + "..." + address.slice(-4);
    return formattedAddress;
  };

  return (
    <div className={scss.root}>
      <div className={scss.nft}>
        <div className={scss.image}>
          {nft && <img src={db_data.nft_img_url} />}
          {/*
          {apparel && <img src={db_data.img_2d_url} />}
          */}
          {apparel &&
            (db_data.mint_chain === "MATIC" ? (
              <iframe
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                height="100%"
                id="AssetMedia--frame"
                sandbox="allow-scripts"
                src={`${visualizer_url}${db_data.product_id}`}
                width="100%"
                style={{ minHeight: "300px" }}
              ></iframe>
            ) : (
              <img src={db_data.img_2d_url} />
            ))}
          {((apparel && db_data.token_id) || (nft && db_data.nft_tokenid)) && (
            <div className={scss.tokenId}>
              {nft && `#${db_data.nft_tokenid}`}
              {apparel && `#${db_data.token_id}`}
            </div>
          )}
        </div>
        {(apparel || db_data.nft_collection !== "") && (
          <div className={scss.content}>
            <div className={scss.content__title}>Collection</div>
            <div className={scss.content__wrapper}>
              {/*
            <div className={scss.content__thumbnail}>
              {nft && <img src={collectionPlaceholder} />}
              {apparel && <img src={collectionPlaceholderApparel} />}
            </div>
            */}
              <div className={scss.informations}>
                <div className={scss.informations__title}>
                  {nft && db_data.nft_collection}{" "}
                  {apparel && collectionName_apparel}
                </div>
                {/*
              <div className={scss.informations__details}>
                {nft && (
                  <Fragment>
                    <div className={scss.floor_price}>
                      <span className={scss.informations__details__title}>
                        Floor price
                      </span>
                      <span className={scss.logo_eth} />
                      <span className={scss.informations__details__value}>
                        {floorPrice}
                      </span>
                    </div>
                    <div className={scss.total_volum}>
                      <span className={scss.informations__details__title}>
                        Total volume
                      </span>
                      <span className={scss.logo_eth} />
                      <span className={scss.informations__details__value}>
                        {totalVolume}
                      </span>
                    </div>
                  </Fragment>
                )}
                {apparel && (
                  <div className={scss.informations__details__apparel}>
                    items<span>{db_data.token_id}</span>
                  </div>
                )}
              </div>*/}
              </div>
            </div>
          </div>
        )}

        {(apparel || getAttributes(db_data).length > 0) && (
          <div className={scss.details}>
            {/*<div className={scss.content__title}>NFT</div>*/}
            {/*
          <div className={scss.informations}>
            <div className={scss.informations__details}>
              <div className={scss.floor_price}>
                <span className={scss.informations__details__title}>
                  Last sale
                </span>
                <span className={scss.informations__details__value}>
                  <span className={scss.logo_eth} />
                  {lastSale}
                </span>
              </div>
              <div className={scss.total_volum}>
                <span className={scss.informations__details__title}>
                  Rarity Rank
                </span>
                <span className={scss.informations__details__value}>
                  {rarityRank}
                </span>
              </div>
            </div>
          </div>
            */}

            <div className={`${scss.content__title}`}>Attributes</div>
            <div className={scss.attributes}>
              {nft &&
                getAttributes(db_data).map(({ name, value, rarity }) => (
                  <div className={scss.attribute}>
                    <div className={scss.attribute__name}>{name}</div>
                    <div className={scss.attribute__value}>{value}</div>
                    <div className={scss.attribute__rarity}>{rarity}</div>
                  </div>
                ))}
              {apparel &&
                getAttributesApparel(db_data).map(({ name, value, rarity }) => (
                  <div className={scss.attribute}>
                    <div className={scss.attribute__name}>{name}</div>
                    <div className={scss.attribute__value}>{value}</div>
                    <div className={scss.attribute__rarity}>{rarity}</div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className={scss.owner}>
          <div className={scss.content__title}>Owner</div>
          <div className={scss.links}>
            <div className={scss.link}>
              <a
                className={scss.item}
                href={marketplace_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={scss.icon}>
                  <img
                    src={
                      db_data.mint_chain
                        ? db_data.mint_chain !== "SOL"
                          ? openSeaImg
                          : magicEdenImg
                        : openSeaImg
                    }
                  />
                </span>
                <span className={scss.name}>
                  {formatWalletAddress(db_data.wallet_address)}
                </span>
              </a>
            </div>
            <div className={scss.link}>
              <a
                className={scss.item}
                href={explorer_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={scss.icon}>
                  <img
                    src={
                      db_data.nft_origin
                        ? explorers[db_data.nft_origin].logo
                        : explorers["ETH"].logo
                    }
                  />
                </span>
                <span className={scss.name}>
                  {formatWalletAddress(db_data.wallet_address)}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
