import React from 'react'

import { IInformations } from "./Informations.types"
import * as scss from './Informations.module.scss'
import { RichText } from 'prismic-reactjs'

export const Informations: React.FC<IInformations> = ({ data }) => {
  return (
    <div className={`${scss.root} g-padding g-width g-margin`}>
      <span className={scss.icon}/>
      <div className={scss.wrapper}>
        <RichText render={data.primary.content_slice.richText} />
      </div>
    </div>
  )
}


