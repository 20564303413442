import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import linkResolver from 'utils/linkResolver'

import { ILink } from "./Link.types"
import * as scss from './Link.module.scss'

export const Link: React.FC<ILink> = ({
  data: {
    primary: {
      link_label: {
        richText
      },
      nav_link: {
        lang,
        type,
        uid
      }
    }
  }
}) => {
  if (!richText || !lang || !type || !uid) return
  let urlTarget = {lang, type, uid}

  return (
    <div className={scss.root}>
      <GatsbyLink to={linkResolver(urlTarget)} className={scss.link}>
        <RichText render={richText}/>
      </GatsbyLink>
    </div>
  )
}


